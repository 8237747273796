import { createMuiTheme, Theme } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { csCZ } from '@material-ui/core/locale';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

const colors = {
  primary: {
    lightest: `#F5FCFF`,
    light: `#DAF3FF`,
    main: `#1FB3E2`,
    dark: `#02214f`,
    contrastText: `#fff`,
  },
  secondary: {
    main: `#02214F`,
  },
  success: {
    main: `#12C158`,
  },
  warning: {
    main: `#F0424D`,
  },
  text: {
    primary: `#02214F`,
    secondary: `#6C7584`,
    lightContrast: `rgba(2, 33, 79, 0.6)`,
    darkContrast: `rgba(255, 255, 255, 0.6)`,
  },
  shadow: `#0000001f`,
  glow: `#ffffff1f`,
  background: {
    default: `#fff`,
  },
};

type Palette = PaletteOptions & typeof colors;
const palette = colors as Palette;
const spacing = factor => `${0.25 * factor}rem`;

export const breakpoints = createBreakpoints({
  values: {
    xs: 600,
    sm: 1024,
    md: 1360,
    lg: 1600,
    xl: 1920,
  },
});

const theme = createMuiTheme(
  {
    palette: palette as any,
    typography: {
      fontFamily: `Barlow, Arial`,
      allVariants: {
        fontStyle: `normal`,
        color: palette.text.primary,
        lineHeight: 1.2,
      },
      h1: {
        fontSize: `2.25rem`,
        [breakpoints.up(`xs`)]: {
          fontSize: `2.5rem`,
        },
        [breakpoints.up(`sm`)]: {
          // 44px
          fontSize: `2.75rem`,
        },
        fontWeight: `bold`,
      },
      h2: {
        fontSize: `1.8rem`,
        [breakpoints.up(`xs`)]: {
          fontSize: `2.25rem`,
        },
        [breakpoints.up(`sm`)]: {
          // 40px
          fontSize: `2.5rem`,
        },
        fontWeight: `bold`,
      },
      h3: {
        fontSize: `1.3rem`,
        [breakpoints.up(`xs`)]: {
          fontSize: `2rem`,
        },
        [breakpoints.up(`sm`)]: {
          // 36px
          fontSize: `2.25rem`,
        },
        fontWeight: `bold`,
      },
      h4: {
        fontSize: `1.3rem`,
        [breakpoints.up(`xs`)]: {
          fontSize: `1.5rem`,
        },
        [breakpoints.up(`sm`)]: {
          // 28px
          fontSize: `1.75rem`,
        },
        fontWeight: `bold`,
      },
      h5: {
        fontSize: `1.3rem`,
        [breakpoints.up(`xs`)]: {
          fontSize: `1.4rem`,
        },
        [breakpoints.up(`sm`)]: {
          // 24px
          fontSize: `1.5rem`,
        },
        fontWeight: `bold`,
      },
      h6: {
        fontSize: `1.1rem`,
        [breakpoints.up(`xs`)]: {
          fontSize: `1.125rem`,
        },
        [breakpoints.up(`sm`)]: {
          // 22px
          fontSize: `1.375rem`,
        },
        fontWeight: `bold`,
      },
      body1: {
        fontSize: `1.1rem`,
        [breakpoints.up(`sm`)]: {
          fontSize: `1.125rem`,
        },
      },
      body2: {
        fontSize: `1.1rem`,
        lineHeight: 1.3,
        [breakpoints.up(`sm`)]: {
          fontSize: `1.125rem`,
        },
        color: palette.text.secondary,
      },
      subtitle1: {
        fontSize: `1.1rem`,
        [breakpoints.up(`sm`)]: {
          fontSize: `1.25rem`,
        },
        color: palette.primary.main,
      },
      caption: {
        fontSize: `0.7rem`,
        [breakpoints.up(`xs`)]: {
          fontSize: `0.75rem`,
        },
        [breakpoints.up(`sm`)]: {
          // 12px
          fontSize: `0.875rem`,
        },
      },
      fontWeightRegular: 500,
      fontWeightMedium: 600,
      fontWeightBold: 700,
    },
    spacing,
    breakpoints,
    overrides: {
      MuiButton: {
        contained: {
          color: `#fff`,
          borderRadius: `100px`,
          textTransform: `none`,
          fontSize: `1.125rem`, // 18px
          padding: `${spacing(4)} ${spacing(8)}`,
          textAlign: `center`
        },
        label: {
          textTransform: `none`,
        },
        containedSizeLarge: {
          padding: `${spacing(6)} ${spacing(16)}`,
          fontSize: `1.375rem`,
        },
      },
      MuiCard: {
        root: {
          borderRadius: spacing(3),
        },
      },
      MuiCardContent: {
        root: {
          padding: `${spacing(4)}`,
          '&:last-child': {
            paddingBottom: `${spacing(4)}`,
          },
          [breakpoints.up(`xs`)]: {
            padding: `${spacing(8)}`,
            '&:last-child': {
              paddingBottom: `${spacing(8)}`,
            },
          },
          '&.small': {
            [breakpoints.up(`xs`)]: {
              padding: `${spacing(5)} ${spacing(8)}`,
              '&:last-child': {
                paddingBottom: `${spacing(5)}`,
              },
            },
          },
        },
      },
      MuiCardHeader: {
        root: {
          padding: `0 0 ${spacing(2)}`,
          color: palette.text.primary,
        },
      },
      MuiLink: {
        root: {
          fontFamily: `Barlow, Arial`,
          transition: `200ms`,
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: `${palette.background.default}`,
          borderTopLeftRadius: `0.5rem`,
          borderTopRightRadius: `0.5rem`,
          borderBottomLeftRadius: `0.5rem`,
          borderBottomRightRadius: `0.5rem`,
          transition: `300ms`,
          border: `1px solid ${palette.shadow}`,
          '&$focused:not($error)': {
            backgroundColor: `${palette.background.default}`,
            border: `1px solid ${palette.primary.main}`,
          },
          '&:hover:not($disabled):not($error)': {
            backgroundColor: `${palette.background.default}`,
            border: `1px solid ${palette.primary.main}`,
          },
          '&$error': {
            border: `1px solid ${palette.warning.main}`,
          },
        },
        input: {
          borderTopLeftRadius: `inherit`,
          borderTopRightRadius: `inherit`,
          borderBottomLeftRadius: `inherit`,
          borderBottomRightRadius: `inherit`,
        }
      },
      MuiFormLabel: {
        root: {
          '&$focused': {
            color: palette.text.secondary,
          },
          '&$error': {
            color: palette.text.secondary,
          },

          '&.Mui-disabled span[class^="MuiFormLabel-asterisk"]': {
            color: `inherit`,
          },
        },
        asterisk: {
          color: palette.warning.main,
        },
      },
      MuiFormHelperText: {
        root: {
          '&$error': {
            color: palette.warning.main,
          },
        },
      },
      MuiFormControlLabel: {
        root: {
          '& .Mui-checked + $label': {
            fontWeight: `bold`,
          },
        },
        label: {
          fontSize: `1rem`,
        },
      },
      MuiRadio: {
        colorSecondary: {
          color: `${palette.text.secondary}`,
          '&$checked': {
            color: `${palette.primary.main}`,
          }
        },
      },
    },
  },
  // set locale
  csCZ
);

export default theme as Theme & { palette: Palette };
