import { Typography, TypographyProps } from '@material-ui/core';
import React, { FC } from 'react';
import theme from 'src/shared/theme';
import styled from 'styled-components';

type FontWeight = `light` | `regular` | `medium` | `bold`;
interface Props {
  className?: string;
  fontWeight?: FontWeight;
}

function getFontWeightProp(fontWeight: FontWeight) {
  switch (fontWeight) {
    case `light`:
      return theme.typography.fontWeightLight;
    case `regular`:
      return theme.typography.fontWeightRegular;
    case `medium`:
      return theme.typography.fontWeightMedium;
    case `bold`:
      return theme.typography.fontWeightBold;

    default:
      return theme.typography.fontWeightRegular;
  }
}

const TypographyWithWeightOverride = styled(Typography)<{ $fontWeight }>`
  ${({ $fontWeight }) => ($fontWeight ? `font-weight: ${getFontWeightProp($fontWeight)};` : null)}
`;

const Text: FC<Props & TypographyProps> = (props) => (
  <TypographyWithWeightOverride
    $fontWeight={props.fontWeight}
    variantMapping={{
      h3: `span`,
      h4: `span`,
      h5: `span`,
      h6: `span`,
      subtitle1: `span`,
      subtitle2: `span`,
    }}
    {...props}
  />
);

export default Text;
